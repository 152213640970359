// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import('urlpattern-polyfill');
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app-home/app-home.js';
import { FeatureItem } from 'app-website-simple/src/components/@types.js';
  //import '../node_modules/app-website-simple/src/app-website-simple.js'

const baseURL: string = (import.meta as any).env.BASE_URL;

const features: FeatureItem[] = [{
  title: "",
  functionalities: [
      {
          screenshotURL:  '/assets/framed_shots/PP_Search_framed_x2.webp',
          headline: "complex search",
          subtitle: "Easily find your perfect piece"
      },
      {
          screenshotURL: '/assets/framed_shots/Product_Details_framed_x2.webp',
          headline: "Perfect Details",
          subtitle: "See if it's your perfect piece"
      }
  ]
}]

export const router = new Router({
  routes: [
    {
      path: resolveRouterPath(),
      title: 'Home',
      render: () => html`<app-home></app-home>`,
    },
    {
      path: resolveRouterPath('about'),
      title: 'About',
      plugins: [lazy(() => import('./pages/app-about/app-about.js'))],
      render: () => html`<app-about></app-about>`,
    },
    {
      path: resolveRouterPath('legal'),
      title: 'Privacy Policy',

      plugins: [lazy(() => import('./pages/app-legal/app-legal.js'))],
     render: () => html`<app-legal></app-legal>`,

    },
    {
      path: resolveRouterPath('perfect-piece'),
      title: 'Perfect Piece',
      plugins: [lazy(() => import('../node_modules/app-website-simple/src/app-website-simple.js'))],

     render: () => html` <app-website-simple .features=${features} iconSrc="/assets/icons/perfect_piece/icons/icon_512.png" tosURL=${resolveRouterPath('legal/perfect-piece/tos-legal')}
     heroImgSrc='/assets/framed_shots/Shop_framed_x2.webp'
     hereImgSrcSet="/assets/framed_shots/Shop_framed_x2.webp 2x, /assets/framed_shots/Shop_framed_x3.webp 3x"
     policyURL=${resolveRouterPath('legal/perfect-piece/privacy-policy')}></app-website-simple>`,
    },
    {
      path: resolveRouterPath('legal/perfect-piece/tos-legal'),
      title: 'Perfect Piece Terms',
      plugins: [lazy(() => import('./pages/app-legal/perfect-piece/tos-legal.js'))],

     render: () => html`<tos-legal></tos-legal>`,
    },
    {
      path: resolveRouterPath('legal/perfect-piece/privacy-policy'),
      title: 'Perfect Piece Policy',
      plugins: [lazy(() => import('./pages/app-legal/perfect-piece/privacy-policy.js'))],

     render: () => html`<privacy-policy></privacy-policy>`,
    }
  ],
});

// This function will resolve a path with whatever Base URL was passed to the vite build process.
// Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
// If no arg is passed to this function, it will return the base URL.

export function resolveRouterPath(unresolvedPath?: string) {
  var resolvedPath = baseURL;
  if (unresolvedPath) {
    resolvedPath = resolvedPath + unresolvedPath;
  }

  return resolvedPath;
}

